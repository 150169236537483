import React from "react";
import { Navigate, Outlet } from "react-router";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";

const PrivateRoute = ({ isValid, children }) => {
  console.log("Children", children, isValid);
  if (!isValid) {
    return <Navigate to="/" replace />;
  } else return children;
  //   ? (
  //   children
  // ) : (
  //   <>
  //     <>
  //       <Header />
  //       <>
  //         <Sidebar />
  //         <Outlet />
  //       </>
  //     </>
  //   </>
  // );
};

export default PrivateRoute;
