import React from "react";
import { Outlet } from "react-router";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

const ProtectedRoute = () => {
  return (
    <>
      <>
        <Header />
        <>
          <Outlet />

          <Footer />
        </>
      </>
    </>
  );
};

export default ProtectedRoute;
