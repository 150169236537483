import React from "react";
import { useTranslation } from "react-i18next";

const index = () => {
  const { t } = useTranslation();

  return (
    <section className="footer-bg">
      <div className="container">
        <div className="footer-inner">
          <footer className="d-flex justify-content-start">
            <div>
              <div>
                <img
                  width={"120px"}
                  height={"40px"}
                  src="./assets/img/white.png"
                />
              </div>
              <div className="footerseting-00449494 pt-5">
                <div className="marginfooterr">
                  <h2 className="footer-t">{t("leearnMore")}</h2>
                  <p className="footer-link">{t("about")}</p>
                  <p className="footer-link">{t("news")} </p>
                  <p className="footer-link">{t("secuirty")}</p>
                </div>
                <div className="marginfooter">
                  <h2 className="footer-t">{t("legal")}</h2>
                  <p className="footer-link">{t("term")}</p>
                  <p className="footer-link">{t("privacy")} </p>
                  <p className="footer-link">{t("terms")} </p>
                </div>
                <div className="marginfooter">
                  <h2 className="footer-t">{t("social")}</h2>
                  <a
                    href="
                    https://youtube.com/@Yourcarcorporation?si=2KcMU5WnhM-0VcUr></a>
                    "
                  >
                    <p className="footer-link">{t("twitter")} </p>
                  </a>
                  <a href="https://lite.tiktok.com/t/ZSN6WSFrp/">
                    {" "}
                    <p className="footer-link">{t("telegram")} </p>
                  </a>
                  <p className="footer-link">{t("instagram")} </p>
                  <p className="footer-link">{t("LinkedIn")} </p>
                  <p className="footer-link">{t("facebook")} </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default index;
