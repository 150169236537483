import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UploadModal = (props) => {
  const { t } = useTranslation();

  const [selectedImages, setSelectedImages] = useState([]);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newImages = [];

    for (let i = 0; i < files.length && i < 4; i++) {
      newImages.push(files[i]);
    }

    setSelectedImages(newImages);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("first", name, phoneNumber, selectedImages);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact_no", phoneNumber);
    // formData.append("car", selectedImages);/
    selectedImages.forEach((image, index) => {
      formData.append(`car`, image);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/car/details`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.msg); // Show success message
        props.onHide(); // Close the modal
      } else {
        const res = await response.json();
        alert(res.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error: Unable to save car details");
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("upload")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div>
              <input
                className="input-login"
                placeholder={t("name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <input
                className="input-login"
                placeholder={t("pNumber")}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <label className="custom-file-upload">
                <input type="file" onChange={handleFileChange} multiple />
                {t("picture")}
              </label>
            </div>
            <div className="row mt-3">
              {selectedImages.map((image, index) => (
                <div key={index} className="col-lg-6 col-xl-6 col-sm-12">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Selected ${index + 1}`}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn header-button my-2 my-sm-0"
            type="submit"
            onClick={handleSubmit}
          >
            {t("btn3")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadModal;
