import React, { useState, useEffect } from "react";
import { api } from "../config/axiosConfig";
import Skeleton from "../components/Skeleton";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Paginate from "../components/Pagination";

const Listing = () => {
  const [skip, setSkip] = useState(0);

  const [carDetails, setCarDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("first", carDetails);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get(
          `/api/car/details?skip=${skip}&limit=${10}`
        );
        console.log("DATA", data);
        setCarDetails(data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.log("error", error);
      }
    };
    getData();
  }, [skip]);

  return (
    <div className="container">
      <div className="row">
        {loading ? (
          // Display Skeleton while loading
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          // Display actual data once loaded
          carDetails?.details?.map((car) => (
            <div className="col-lg-6 my-5" key={car._id}>
              <div className="cards-001 h-100">
                <h3>{car.name}</h3>
                <p className="pt-2">{car?.contact_no}</p>
                <Carousel showThumbs={false}>
                  {car.car_pics.map((pic, index) => (
                    <div key={index}>
                      <img
                        className="listing-image"
                        src={pic}
                        alt={`Car ${car.name} - ${index + 1}`}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          ))
        )}
        <div>
          <Paginate
            limit={10}
            setSkip={setSkip}
            skip={skip}
            data={carDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default Listing;
