// Skeleton.jsx

import React from "react";

const Skeleton = () => {
  return (
    <div className="col-lg-3 my-5">
      <div className="cards-001 h-100">
        <div className="skeleton-title"></div>
        <div className="skeleton-text"></div>
        <div className="d-flex justify-content-center pt-4">
          <div className="skeleton-image"></div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
