import axios from "axios";
// import { baseUrl } from "./BaseUrl";

const baseURL = process.env.REACT_APP_SERVER_URL;

const authApi = axios.create({
  baseURL: baseURL,
});

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  function (config) {
    // const token = store.getState()?.user?.token;
    const token = localStorage.getItem("token");
    console.log("token", token);
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    console.log(config);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export { api };
