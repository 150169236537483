import React from "react";
import { useTranslation } from "react-i18next";

const YccOffers = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-5">
      <div className="row">
        <div className="row mb-5">
          <div className="col-lg-3 col-6 mt-5">
            <img
              height={"60px"}
              width={"150px"}
              className=""
              src="./assets/img/1.png"
            />
          </div>
          <div className="col-lg-3 col-6 mt-5">
            <div className="d-flex justify-content-center">
              <img height={"60px"} className="" src="./assets/img/2.png" />
            </div>
          </div>
          <div className="col-lg-3 col-6 mt-5">
            <div className="d-flex justify-content-center">
              <img className="" src="./assets/img/3.png" />
            </div>
          </div>
          <div className="col-lg-3 col-6 mt-5">
            <div className="d-flex justify-content-center">
              <img className="" src="./assets/img/4.png" />
            </div>
          </div>
        </div>
        <div className="col-xl-6 ">
          <div className="d-flex justify-content-center">
            <img className="sectiopic-0002" src="./assets/img/car2.png" />
          </div>
        </div>
        <div className="col-xl-6 mt-3">
          <div className="">
            <div className="">
              <h1 className="main-headin ">{t("yccOffer")}</h1>
              <p className="third-headingsss  pt-3">{t("yccOffersub")}</p>
            </div>

            <div className="d-flex">
              <img className="qa" src="./assets/img/g.png" />
              <div>
                <h3 className="steps-001 pt-3">{t("freeDoor")}</h3>
                <p className="steps-002 pt-2">{t("freeDoort")}</p>
              </div>
            </div>
            <div className="d-flex">
              <img className="qa" src="./assets/img/g1.png" />
              <div>
                <h3 className="steps-001 pt-3">{t("cPricing")}</h3>
                <p className="steps-002 pt-2">{t("cPricingt")}</p>
              </div>
            </div>
            <div className="d-flex">
              <img className="qa" src="./assets/img/g2.png" />
              <div>
                <h3 className="steps-001 pt-3">{t("noFee")}</h3>
                <p className="steps-002 pt-2">{t("noFeet")}</p>
              </div>
            </div>
            <div className="d-flex">
              <img className="qa" src="./assets/img/g3.png" />
              <div>
                <h3 className="steps-001 pt-3">{t("fastProcess")}</h3>
                <p className="steps-002 pt-2">{t("fastProcesst")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YccOffers;
