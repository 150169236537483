import React from "react";
import { useTranslation } from "react-i18next";
const Note = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className="notesection">
          <h1 className="main-headin">{t("noteHead")}</h1>
          <p className="third-headingsss pt-5 pb-5">{t("Note")}</p>
        </div>
      </div>
    </div>
  );
};

export default Note;
