import React from "react";
import { useTranslation } from "react-i18next";
import UploadModal from "./UploadModal";

const HeroSection = () => {
  const { t } = useTranslation();
  const [modalShow2, setModalShow2] = React.useState(false);

  return (
    <div>
      {/* section two */}
      <div className="row ">
        <div className="d-flex justify-content-center btnycytdyd-0001">
          <div className="">
            <button
              onClick={() => setModalShow2(true)}
              class="btn header-button-2 "
            >
              {t("btn2")}
            </button>
          </div>
          <div className="ml-3">
            <p className="headermbl">
              <a href="tel:0296341015">0296-34-1015</a>
            </p>
          </div>
        </div>
        <div className="col-xl-5 col-lg-5">
          <div className="space-for-mainsec">
            <div className="car">
              <h1 className="main-headin">{t("mainHeading")}</h1>
              <h3 className="third-headings pt-3">{t("heroText")}</h3>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-lg-7">
          <div className="space-for-mainsec d-flex justify-content-center">
            <img className="herosectionimg" src="./assets/img/car.png" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3">
          <div className="d-flex justify-content-center">
            <div>
              <p className="sub-headingss-001 mt-4">{t("tagLine2")}</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="d-flex justify-content-center">
            <p className="sub-headingss-001 mt-4">{t("tagLine3")}</p>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="d-flex justify-content-center">
            <p className="sub-headingss-001 mt-4">{t("tagLine1")}</p>
          </div>
        </div>
        <UploadModal show={modalShow2} onHide={() => setModalShow2(false)} />
      </div>
      {/* end section two */}
    </div>
  );
};

export default HeroSection;
