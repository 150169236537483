import React, { useEffect, useState } from "react";
import LoginModal from "../../components/LoginModal";
import { Link, Navigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import UploadModal from "../../components/UploadModal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { reset } from "../../store/slices/userSlice";
import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// index.js or App.js

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Assuming you have a state for login status
  // const [token, setToken] = useState(localStorage.getItem("token"));
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    dispatch(reset());
    toast.success("Successfully logged out!");
  };

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // useEffect(() => {
  //   setToken(localStorage.getItem("token"));
  // }, [localStorage, handleLogout]);

  // console.log(token);
  return (
    <header style={{ position: "sticky", top: 0, zIndex: 1000 }}>
      <div className="container">
        <nav class="navbar navbar-expand-lg navbar-light pt-3">
          <Link to={"/"}>
            <img width={"130px"} height={"40px"} src="./assets/img/white.png" />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              {isLoggedIn && (
                <li className="nav-item">
                  <a className="nav-link" href="/listing">
                    Listing
                  </a>
                </li>
              )}
            </ul>
            {/* <form class="form-inline my-2 my-lg-0"> */}
            <div className="mr-3 msdwdwdww77">
              <Dropdown className="">
                <Dropdown.Toggle
                  className="header-button-2"
                  id="dropdown-basic"
                >
                  Japanese
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    English
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("ja")}>
                    Japanese
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="moblieeebtn">
              <button
                onClick={() => setModalShow2(true)}
                class="btn header-button-2 my-2 my-sm-0"
              >
                {t("btn2")}
              </button>
              <Link to={"/blog"}>
                <button class="btn header-button-2 my-2 ml-3 my-sm-0">
                  {t("btn6")}
                </button>
              </Link>
              {token ? (
                <button
                  onClick={handleLogout}
                  class="btn header-button my-2 my-sm-0 ml-3"
                >
                  Logout
                </button>
              ) : (
                <button
                  onClick={() => setModalShow(true)}
                  class="btn header-button my-2 my-sm-0 ml-3"
                >
                  {t("btn1")}
                </button>
              )}
            </div>
            <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
            <UploadModal
              show={modalShow2}
              onHide={() => setModalShow2(false)}
            />
            {/* </form> */}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
