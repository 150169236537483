import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import Home from "./pages/Home";
import Listing from "./pages/Listing";
import PrivateRoute from "./PrivateRoute";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Blog from "./pages/Blog";
import BlogDeatils from "./pages/BlogDeatils";

function App() {
  // const [tokenn, setToken] = useState(localStorage.getItem("token"));
  const { token } = useSelector((state) => state.user);
  console.log("to", token);
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log("sssssssssssss");
  //   setToken(token);
  // }, [tokenn]);
  // console.log("mmmmmmm", tokenn);

  return (
    <>
      <BrowserRouter>
        <ToastContainer position="top-right" reverseOrder={false} />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blogdetails/:id" element={<BlogDeatils />} />

            <Route
              path="listing"
              element={
                <PrivateRoute isValid={token ? true : false}>
                  <Listing />
                </PrivateRoute>
              }
            />
          </Route>
          {/* <Route element={<PrivateRoute isValid={token ? true : false} />}>
              <Route exact path="/listing" element={<Listing />} />
            </Route> */}
          {/* <Route
              exact
              path="/listing"
              element={
                <PrivateRoute redirectLink="/">
                  <Listing />
                </PrivateRoute>
              }
            />*/}
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
