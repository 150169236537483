// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        subNav: "Inquiries by phone(0296 34 1015)",
        mainHeading: "Your Car Corporation",
        heroText:
          "Introducing Your Car  Corporation, where convenience meets value! At Your Car Corporation, we pride ourselves on offering a hassle-free solution for selling your vehicle. Imagine selling your car, truck, or SUV without ever leaving your doorstep and receiving the best price for it – all within 24 hours. We understand that your time is valuable, and that's why we've streamlined the process to make selling your vehicle as easy as possible. Discover a new way to sell your car with Your Car Corporation, where we come to you and make sure you get the best deal, fast.",
        tagLine1: "Convenient & transparent selling process",
        tagLine2: "Secure payment methods",
        tagLine3: "24/7 customer support",
        yccOffersub:
          "Ycc offers an easy service of 24/7 to the customers to sell their vehicles at one click",
        cPricing: "Competitive Pricing",
        cPricingt:
          "We offer the best price for your vehicle based on market conditions.",
        noFee: "No Hidden Fees",
        noFeet:
          "We are committed to transparent pricing with no surprise charges. Secure Payment: Receive your payment through a secure and trusted method of your choice.",
        fastProcess: "24/7 Support",
        fastProcesst:
          "Sell your car within 24 hours at doorstep, without the need for multiple meetings or negotiations.",
        yccOffer: "What YCC Offers",
        freeDoor: "Free doorstep services",
        freeDoort:
          "Ycc offers all services free including picking up vehicles , ownership transfer.",
        inPhone: "Send Inquiries by ",
        inPhonet: "Inquiries by phone",
        inEmail: "Inquiries by email",
        noteHead: "Please Note",
        Note: "All sales and transactions are subject to local, state, and federal laws and regulations. We operate in accordance with these laws, and it's crucial for our customers to do the same.",
        leearnMore: "Learn More",
        legal: "Legal",
        social: "Socials",
        about: "About",
        news: "News",
        secuirty: "Security",
        term: "Terms of Use",
        privacy: "Privacy Policy",
        terms: "Terms & Conditions",
        twitter: "YouTube",
        telegram: "Tik Tok",
        instagram: "Instagram",
        LinkedIn: "LinkedIn",
        facebook: "Facebook",
        btn1: "Login",
        btn2: "Sell your Car",
        btn6: "Our Blog",
        btn3: "Submit",
        upload: "Upload",
        picture: "Upload Picture",
        pNumber: "Phone Number",
        name: "Name",
      },
    },
    ja: {
      translation: {
        subNav: "お電話でのお問い合わせ（0296 34 1015）",
        mainHeading: "ユア・カーコーポレーション",
        heroText:
          "利便性と価値を両立するユア・カー・コーポレーションをご紹介します! ユア・カー・コーポレーション では、お客様の車を販売するための手間のかからないソリューションを提供することに誇りを持っています。自宅から一歩も出ることなく、車、トラック、SUV を 24 時間以内に最高の価格で売却できることを想像してみてください。お客様の時間が貴重であることを当社は理解しており、そのため当社では車両の販売をできるだけ簡単にするためにプロセスを合理化しました。 ユア・カー・コーポレーション であなたの車を販売する新しい方法を発見してください。私たちはあなたのところに来て、迅速に最高の取引を確実に得られるようにします",
        tagLine1: "便利で透明な販売プロセス",
        tagLine2: "安全な支払い方法",
        tagLine3: "24時間年中無休のカスタマーサポート",
        yccOffersub:
          "ユア・カーコーポレーションは、24 時間 365 日、ワンクリックでお客様の車を販売できる簡単なサービスを提供しています。",
        cPricing: "競争力のある価格設定",
        cPricingt: "市場に基づいてお客様の車両に納得な価格を提案します。",
        noFee: "安全、安心の支払い方法",
        noFeet: "安全で信頼できる方法でお客様とお取引を致します。",
        fastProcess: "各国翻訳による迅速かつ細やかなサービス",
        fastProcesst:
          "ワンクリックで車を販売し、ご自宅で全てのサービスを受ける事が出来ます。車の引き取りや、支払い方法など24時間以内にお客様との取引が可能です。",
        yccOffer: "ユア・カーコーポレーションが提供する",
        freeDoor: "弊社無料でのお出向きサービス",
        freeDoort:
          "ユア・カーコーポレーションは、車両の引き取り、所有権の譲渡を含むすべてのサービスを無料で提供します。",
        inPhone: "お問い合わせの送信先",
        inEmail: "ご質問や車の売却については",
        noteHead: "注記",
        Note: "すべての販売と取引は、地方、州、連邦の法律と規制の対象となります。当社はこれらの法律に従って業務を行っており、お客様にとっても同様のことを行うことを重視しております免責事項.",
        leearnMore: "もっと詳しく知る",
        legal: "法律上の",
        social: "ソーシャル",
        about: "について",
        news: "ニュース",
        secuirty: "安全",
        term: "利用規約",
        privacy: "プライバシーポリシー",
        terms: "利用規約",
        twitter: "ユーチューブ",
        telegram: "チクタク",
        instagram: "インスタグラム",
        LinkedIn: "リンクトイン",
        facebook: "フェイスブック",
        btn1: "ログイン",
        btn2: "車を売る",
        btn6: "私たちのブログ",
        btn3: "提出する",
        upload: "アップロード",
        picture: "写真をアップロードする",
        pNumber: "電話番号",
        name: "名前",
      },
    },
  },
  lng: "ja", // Default language
  fallbackLng: "ja",
  interpolation: {
    escapeValue: false, // React already escapes the content
  },
});

export default i18n;
