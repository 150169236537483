import React from "react";
import { useTranslation } from "react-i18next";
const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="main-inquireisdsd-001">
            <div className="row">
              <h3 className="idebefefn-001">{t("inPhone")}</h3>
              <div className="col-xl-4 mt-3">
                <div>
                  <p className="idebefefn-003 mt-3 py-2">080-30153100</p>
                </div>
              </div>
              <div className="col-xl-4 mt-3">
                <p className="idebefefn-003 mt-3 py-2">0296-34-1015</p>
              </div>
              <div className="col-xl-4 mt-3">
                <p className="idebefefn-003 mt-3 py-2">0296-34-1016</p>
              </div>

              <div className="mt-3">
                <p className="idebefefn-003 mt-3 py-2">sales@japanycc.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
