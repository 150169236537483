import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../config/axiosConfig";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const [blog, setBlog] = useState(null);
  const { id } = useParams();
  console.log(blog, "fferrffrfrfff");
  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await api.get(`/api/blogs/${id}`);
        setBlog(response?.data?.data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetails();
  }, []);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-8 mt-5 mb-5">
            <div className="blog-post">
              <h2 className="blog-post-title">{blog.title}</h2>
              <p className="blog-post-meta">
                {new Date(blog.created_at).toDateString()} by{" "}
                <a href="#">{blog.author}</a>
              </p>
              <img
                className="blogpic"
                src={blog.blog_pics}
                alt={blog.title}
                width="800px"
                height="400px"
              />
              <p className="blog-post-descriptions">{blog.description}</p>
              {/* ... other blog details ... */}
            </div>

            {/* Add navigation or other details as needed */}
          </div>

          {/* Add sidebar or other details as needed */}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
