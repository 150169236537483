// import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../store/slices/userSlice";
import { toast } from "react-toastify";

const LoginModal = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = localStorage.getItem("token");
  console.log(token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //
  // api call
  const handleLogin = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/user/signin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Login successful
        // Store token in localStorage
        console.log(data, "dddddddddddddd");
        localStorage.setItem("token", data.token);
        dispatch(setToken(data.token));

        toast.success("Successfully logged in!");
        props.onHide();
        navigate("/listing"); // Redirect to /listing
      } else {
        // Login failed
        // Handle error (e.g., show error message)
      }
    } catch (error) {
      toast.error("Invalid email or password");
      console.error("Error:", error);
      // Handle network error or other exceptions
    }
  };
  // api call end
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };
  // handle input chnages
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailError(validateEmail(newEmail) ? "" : "Email not valid");
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(
      validatePassword(newPassword)
        ? ""
        : "Password must be at least 8 characters long"
    );
  };
  console.log("handleLogin called");
  try {
    // ... (existing code)
    if (response.ok) {
      console.log("Login successful");
      setIsLoggedIn(true); // Set the user as logged in
      // ...
    } else {
      console.log("Login failed");
    }
  } catch (error) {
    console.error("Error:", error);
  }

  const isFormValid = !emailError && !passwordError;

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <input
              className="input-login"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
            {emailError && <div className="error-message">{emailError}</div>}
            <div className="mt-3">
              <input
                className="input-login"
                placeholder="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn header-button my-2 my-sm-0"
            type="submit"
            disabled={!isFormValid}
            onClick={handleLogin}
          >
            Login
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginModal;
