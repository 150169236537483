import React, { useState } from "react";
import AccorDian from "../components/AccorDian";
import DownloadApp from "../components/DownloadApp";
import HeroSection from "../components/HeroSection";
import Note from "../components/Note";
import YccOffers from "../components/YccOffers";

const Home = () => {
  return (
    <div className="container">
      {/* hero */}
      <HeroSection />
      {/* section  threeee */}
      <YccOffers />
      <DownloadApp />
      {/* section three endedd */}
      <Note />

      {/* accordian */}
      <AccorDian />
      {/* accordian end */}
    </div>
  );
};

export default Home;
