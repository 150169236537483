import React, { useEffect, useState } from "react";
import { api } from "../config/axiosConfig";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const BlogDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await api.get("/api/blogs/?skip=0&limit=10");
        setBlogs(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const truncateDescription = (description, maxLength = 150) => {
    if (description.length <= maxLength) {
      return description;
    }
    return description.substring(0, maxLength) + "...";
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <h3>
            カーバイイングに関する専門の知識とヒントをご覧ください：スマートなカーバイイングの究極のガイド
          </h3>
          {loading
            ? // Skeleton loading when data is still being fetched
              Array.from({ length: 10 }).map((_, index) => (
                <div key={index} className="col-lg-4 mt-5 mb-5">
                  <div className="blog-main h-100">
                    <Skeleton height={200} />
                    <div className="blog-post">
                      <Skeleton height={20} width={100} />
                      <Skeleton count={2} />
                      <Link className="blodredmorebtn" to="#">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : // Render fetched blog data
              blogs.map((blog) => (
                <div key={blog._id} className="col-lg-6 col-xl-4 mt-5 mb-5">
                  <div className="blog-main h-100">
                    <img
                      className="blogimage"
                      src={blog.blog_pics}
                      alt={blog.title}
                    />
                    <div className="blog-post">
                      <h2 className="blog-post-title">{blog.title}</h2>
                      <p className="blog-post-meta">
                        {new Date(blog.created_at).toDateString()} by{" "}
                      </p>
                      <p className="blog-post-descriptions">
                        {truncateDescription(blog.description, 150)}
                      </p>
                      <Link
                        className="blodredmorebtn"
                        to={`/blogdetails/${blog._id}`}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
